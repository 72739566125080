import Image from 'next/image';
import { useTranslations } from 'next-intl';

import Container from './Container';

const CallToAction = () => {
  const t = useTranslations('JoinSection');
  return (
    <div className="relative py-16 overflow-hidden z-20">
      <div
        aria-hidden="true"
        className="absolute inset-0 h-max w-full m-auto grid grid-cols-2 -space-x-52 opacity-40 dark:opacity-20"
      >
        <div className="blur-[106px] h-56 bg-gradient-to-br from-primary to-purple-400 dark:from-blue-700"></div>
        <div className="blur-[106px] h-32 bg-gradient-to-r from-cyan-400 to-indigo-300 dark:to-indigo-600"></div>
      </div>
      <Container>
        <div className="relative">
          <div className="flex items-center justify-center -space-x-2">
            <Image
              width="400"
              height="400"
              src="/img/avatars/mi.68a0f720.jpeg"
              alt="member photo"
              className="h-8 w-8 rounded-full object-cover"
            />
            <Image
              width="200"
              height="200"
              src="/img/avatars/claire.bfdbc161.jpeg"
              alt="member photo"
              className="h-12 w-12 rounded-full object-cover"
            />
            <Image
              width="200"
              height="200"
              src="/img/avatars/cmz.jpg"
              alt="member photo"
              className="z-10 h-16 w-16 rounded-full object-cover"
            />
            <Image
              width="200"
              height="200"
              src="/img/avatars/lori.07099057.jpeg"
              alt="member photo"
              className="relative h-12 w-12 rounded-full object-cover"
            />
            <Image
              width="200"
              height="200"
              src="/img/avatars/zak.be2e6aeb.jpeg"
              alt="member photo"
              className="h-8 w-8 rounded-full object-cover"
            />
          </div>
          <div className="mt-6 m-auto space-y-6 md:w-8/12 lg:w-7/12">
            <h1 className="text-center text-3xl font-bold text-black md:text-5xl">
              {t('title')}
            </h1>
            <p className="text-center text-xl text-slate-600">
              {t('description')}
            </p>
            {/* <div className="flex flex-wrap justify-center gap-6">
              <a
                href="https://app.url.zip"
                className="relative flex h-12 w-full items-center justify-center px-8 before:absolute before:inset-0 before:rounded-full before:bg-blue-700 before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
              >
                <span className="relative text-base font-semibold text-white dark:text-dark">
                  Get Started
                </span>
              </a>
            </div> */}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default CallToAction;
