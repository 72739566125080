import type { FC, ReactNode } from 'react';

import AppHeader, { AppHeaderMode } from './AppHeader';
import Footer from './Footer';

type LayoutProps = {
  children: ReactNode;
  mode: AppHeaderMode;
};

const Layout: FC<LayoutProps> = ({ children, mode }) => {
  return (
    <>
      <AppHeader mode={mode} />
      <main className="space-y-40">{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
