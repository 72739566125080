import { FC, useEffect, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import classNames from 'classnames';

import Container from './Container';

export enum AppHeaderMode {
  Light = 'light',
  Dark = 'dark',
}

type AppHeaderProps = {
  mode: AppHeaderMode;
};

const AppHeader: FC<AppHeaderProps> = ({ mode }) => {
  const [isLight, setIsLight] = useState(mode === AppHeaderMode.Light);
  const [logo, setLogo] = useState(
    isLight ? '/img/logo.png' : '/img/logo-dark.png',
  );

  useEffect(() => {
    if (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
    ) {
      setLogo('/img/logo.png');
      setIsLight(true);
    }
  }, []);

  const hamburgerClasses = classNames(
    'm-auto h-0.5 w-5 rounded transition duration-300',
    {
      'bg-white': isLight,
      'bg-black': !isLight,
    },
  );
  const titleClasses = classNames('font-sans text-2xl font-bold', {
    'text-white': isLight,
    'text-black': !isLight,
  });
  const menuClasses = classNames(
    'text-white dark:text-gray-300 lg:pr-4 lg:w-auto w-full lg:pt-0',
    {
      'text-white': isLight,
      'sm:text-black': !isLight,
    },
  );

  return (
    <header>
      <nav className="z-10 w-full absolute">
        <Container>
          <div className="flex flex-wrap items-center justify-between py-2 gap-6 md:py-4 md:gap-0 relative">
            <input
              aria-hidden="true"
              type="checkbox"
              name="toggle_nav"
              id="toggle_nav"
              className="hidden peer"
            />
            <div className="relative z-20 w-full flex justify-between lg:w-max md:px-0">
              <Link
                href="/"
                aria-label="logo"
                className="flex space-x-2 items-center"
              >
                <Image
                  className="w-auto h-8"
                  src={logo}
                  alt="url.zip logo"
                  width={32}
                  height={32}
                />
                <p className={titleClasses}>url.zip</p>
              </Link>

              <div className="relative flex items-center lg:hidden max-h-10">
                <label
                  role="button"
                  htmlFor="toggle_nav"
                  aria-label="humburger"
                  id="hamburger"
                  className="relative p-6 -mr-6"
                >
                  <div
                    aria-hidden="true"
                    id="line"
                    className={hamburgerClasses}
                  ></div>
                  <div
                    aria-hidden="true"
                    id="line2"
                    className={`mt-2 ${hamburgerClasses}`}
                  ></div>
                </label>
              </div>
            </div>
            <div
              aria-hidden="true"
              className="fixed z-10 inset-0 h-screen w-screen bg-indigo-600/50 backdrop-blur-2xl origin-bottom scale-y-0 transition duration-500 peer-checked:origin-top peer-checked:scale-y-100 lg:hidden dark:bg-gray-900/70"
            ></div>
            <div
              className="flex-col z-20 flex-wrap gap-6 p-8 rounded-lg border border-gray-100 bg-transparent shadow-2xl shadow-gray-600/10 justify-end w-full invisible opacity-0 translate-y-1  absolute top-full left-0 transition-all duration-300 scale-95 origin-top
                            lg:relative lg:scale-100 lg:peer-checked:translate-y-0 lg:translate-y-0 lg:flex lg:flex-row lg:items-center lg:gap-0 lg:p-0 lg:bg-transparent lg:w-7/12 lg:visible lg:opacity-100 lg:border-none
                            peer-checked:scale-100 peer-checked:opacity-100 peer-checked:visible lg:shadow-none
                            dark:shadow-none dark:bg-gray-800 dark:border-gray-700"
            >
              <div className={menuClasses}>
                <ul className="tracking-wide font-medium lg:text-sm flex-col flex lg:flex-row gap-6 lg:gap-0">
                  <li>
                    <Link
                      href="/team"
                      className="block md:px-4 transition hover:underline"
                    >
                      <span>Team</span>
                    </Link>
                  </li>
                </ul>
              </div>

              {/* <div className="mt-12 lg:mt-0">
                <a
                  href="https://app.url.zip"
                  className="relative flex h-9 w-full items-center justify-center px-4 before:absolute before:inset-0 before:rounded-full before:bg-blue-500 before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
                >
                  <span className="relative text-sm font-semibold text-white">
                    Get Started
                  </span>
                </a>
              </div> */}
            </div>
          </div>
        </Container>
      </nav>
    </header>
  );
};

export default AppHeader;
