import { useTranslations } from 'next-intl';

import FeatureItem from '../common/FeatureItem';
import Container from './Container';

const FeatureSection = () => {
  const t = useTranslations('FeatureSection');

  return (
    <section className="relative pt-16 pb-32 overflow-hidden bg-white space-y-24 z-20">
      <Container>
        <FeatureItem
          featureContent={{
            title: t('sectionOne.title'),
            description: t('sectionOne.description'),
            image: 'img/link_shortener_mvf6.svg',
          }}
          position="right"
        />
        <FeatureItem
          featureContent={{
            title: t('sectionTwo.title'),
            description: t('sectionTwo.description'),
            image: 'img/Hand-coding_Artboard-1.svg',
          }}
          position="left"
        />
        <FeatureItem
          featureContent={{
            title: t('sectionThree.title'),
            description: t('sectionThree.description'),
            image: 'img/Developer-activity.svg',
          }}
          position="right"
        />
      </Container>
    </section>
  );
};

export default FeatureSection;
