import { useTranslations } from 'next-intl';
import Image from 'next/image';
import styled from '@emotion/styled';
import { useCallback } from 'react';
import type { Engine } from 'tsparticles-engine';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';

import ShorterForm from '../common/sharedComponents/Shorter';
import Container from './Container';
import { Color } from '../styles/theme';

const Section = styled.section(
  () => `
    background-position:center center;
    background-size:cover;
    background-color: ${Color.prussianBlue};
    color: ${Color.white};
    height: 100vh;
    position: relative;
    z-index:1;
    overflow: hidden;
  `,
);
const ParticlesContainer = styled.div`
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: -9;

  canvas {
    position: absolute !important;
    top: 0;
    left: 0;
  }
`;

const PARTICLES_PARAMS = {
  fps_limit: 60,
  interactivity: {
    events: {
      onclick: { enable: true, mode: 'push' },
      onhover: {
        enable: true,
        mode: 'repulse',
        parallax: { enable: false, force: 60, smooth: 10 },
      },
      resize: true,
    },
    modes: {
      push: { quantity: 4 },
      attract: { distance: 200, duration: 0.4, factor: 5 },
    },
  },
  particles: {
    color: { value: '#ffffff' },
    line_linked: {
      color: '#ffffff',
      distance: 150,
      enable: true,
      opacity: 0.4,
      width: 1,
    },
    move: {
      attract: { enable: false, rotateX: 600, rotateY: 1200 },
      bounce: false,
      enable: true,
      random: false,
      speed: 2,
      straight: false,
    },
    number: { density: { enable: true, value_area: 800 }, value: 80 },
    opacity: {
      anim: { enable: false, opacity_min: 0.1, speed: 1, sync: false },
      random: false,
      value: 0.5,
    },
    shape: {
      character: {
        fill: false,
        font: 'Verdana',
        style: '',
        value: '*',
        weight: '400',
      },
      polygon: { nb_sides: 5 },
      stroke: { color: '#000000', width: 0 },
      type: 'circle',
    },
    size: {
      anim: { enable: false, size_min: 0.1, speed: 40, sync: false },
      random: true,
      value: 5,
    },
  },
  polygon: {
    draw: { enable: false, lineColor: '#ffffff', lineWidth: 0.5 },
    move: { radius: 10 },
    scale: 1,
    type: 'none',
    url: '',
  },
  retina_detect: true,
};

const Hero = () => {
  const t = useTranslations('Hero');
  const particlesInit = useCallback(async (engine: Engine) => {
    await loadFull(engine);
  }, []);

  return (
    <Section className="pt-8 sm:pt-12 relative lg:py-48">
      <ParticlesContainer>
        <Particles
          id="tsparticles"
          init={particlesInit}
          options={PARTICLES_PARAMS}
        />
      </ParticlesContainer>
      <Container>
        <div className="mt-14">
          <div className="mt-6">
            <h1 className="text-4xl font-black tracking-normal text-white sm:text-6xl md:text-7xl">
              {t('title')}
              <span className="text-primary">.</span>
            </h1>
            <h2 className="mt-6 text-lg text-white-500 sm:text-xl">
              {t('eyebrow')}
            </h2>
          </div>
          <div className="mt-10 space-y-4">
            <ShorterForm classes="mt-4 " />
          </div>
          <div className="mt-20">
            <div className="inline-flex items-center">
              <Image
                src="/img/avatars/cmz.jpg"
                alt="Cristian Moreno - @khriztianmoreno"
                className="object-cover inline-block mr-3 border-2 border-primary rounded-full sm:mr-2 h-14 w-14"
                width={56}
                height={56}
              />
              <div>
                <p className="sm:pl-2.5 text-base font-black tracking-wider text-white-800 sm:text-lg">
                  {t('quote')}
                </p>
                <p className="sm:pl-2.5 text-sm sm:text-base font-bold text-white-500">
                  Cristian Moreno
                </p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default Hero;
