import FeatureSection from '../components/FeatureSection';
import Head from '../common/sharedComponents/MetaHead';
import HeroLink from '../components/HeroLink';
import CallToAction from '../components/CallToAction';
import QuoteSection from '../components/QuoteSection';
import Layout from '../components/Layout';
import { AppHeaderMode } from '../components/AppHeader';

function Home() {
  return (
    <Layout mode={AppHeaderMode.Light}>
      <Head />
      <div id="app">
        <div className="site-content bg-white overflow-hidden">
          <HeroLink />
          <FeatureSection />
          <QuoteSection />
          <CallToAction />
        </div>
      </div>
    </Layout>
  );
}

export async function getStaticProps({ locale }: { locale: string }) {
  return {
    props: {
      // You can get the messages from anywhere you like. The recommended
      // pattern is to put them in JSON files separated by language and read
      // the desired one based on the `locale` received from Next.js.
      messages: (await import(`../common/locales/${locale}.json`)).default,
    },
  };
}

export default Home;
