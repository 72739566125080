import { FC, FormEvent, useState } from 'react';
import { useTranslations } from 'next-intl';
import { CopyToClipboard } from 'react-copy-to-clipboard';

type ShorterProps = {
  classes?: string;
};

const ShorterForm: FC<ShorterProps> = ({ classes }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [shortUrl, setShortUrl] = useState('');
  const t = useTranslations('Shorter');

  const className = `
    group relative grid gap-2 grid-cols-1 sm:w-full sm:flex sm:items-center sm:flex-wrap
    ${classes}
  `;

  const getShortUrl = async (longUrl: string) => {
    const payload = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ url: longUrl }),
    };

    try {
      const result = await fetch('/api/shortUrl', payload);
      setIsSubmitted(true);
      const { shortUrl } = await result.json();
      if (shortUrl) {
        setShortUrl(shortUrl);
      }
    } catch (error) {
      // Show error message
      setIsProcessing(false);
    }
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    const { target } = event;
    event.preventDefault();

    if (!(target instanceof HTMLFormElement)) {
      return;
    }

    const formData = new FormData(target);
    const form = Object.fromEntries(formData);

    if (!form.url) {
      return;
    }

    setIsProcessing(true);
    await getShortUrl(form.url as string);
  };

  const handleCopy = () => {
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
      setIsSubmitted(false);
      setIsProcessing(false);
      setShortUrl('');
    }, 3000);
  };

  if (isSubmitted) {
    return (
      <div className={className}>
        <CopyToClipboard text={shortUrl} onCopy={handleCopy}>
          <span className="italic">{shortUrl}</span>
        </CopyToClipboard>
        {isCopied ? (
          <span className="absolute top-7 rounded transition-all bg-blue-600 p-2 text-xs text-white">
            ✨ {t('form.success')}
          </span>
        ) : null}
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className={className}>
      <label htmlFor="cta-email" className="sr-only">
        {t('form.label')}
      </label>
      <input
        id="cta-email"
        name="url"
        type="url"
        className="block w-full px-5 py-3 text-base text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md shadow-sm focus:border-primary focus:ring-primary focus-visible:ring-primary flex-1"
        required
        readOnly={isProcessing}
        placeholder={t('form.placeholder')}
      />
      <div>
        {!isProcessing ? (
          <button
            className="text-white bg-blue-500 sm:hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 relative "
            type="submit"
          >
            {t('form.submit')}
          </button>
        ) : (
          <button
            disabled
            className="text-white inline-flex items-center bg-blue-500 sm:hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            type="submit"
          >
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            {t('form.processing')}
          </button>
        )}
      </div>
    </form>
  );
};

export default ShorterForm;
